import React from "react";
import Layout from "../components/layout";
import Banner from "../components/banner/";
import CollapsibleText from "../components/collapsibleText";
import ContactForm from "../components/contactForm";
import HiddenCollaborationPageContent from "../components/collaboration/hiddenCollaborationPageContent";
import wrapWithLayout from "../components/layout/wrapWithLayout";

const CollaborateHiddenPage = wrapWithLayout(
  ({ data }) => {
    const banner = data?.banner;
    const intro_text = data?.intro_text;
    const details_content = data?.details_content;
    const button = data?.button;
    const contact_form_enabled = data?.contact_form_enabled;
    const product = data?.product;

    return (
      <>
        {banner && <Banner {...banner} />}
        <div className="container">
          <div className="mt-3">
            {intro_text && <CollapsibleText {...intro_text} />}
          </div>
          {details_content && (
            <HiddenCollaborationPageContent
              {...details_content}
              button={button}
            />
          )}
          <div className="mt-5">
            {contact_form_enabled && product && (
              <ContactForm product={product} />
            )}
          </div>
        </div>
      </>
    );
  },
  ({ pageContext }) => pageContext?.data
);

export default CollaborateHiddenPage;
