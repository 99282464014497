import React from "react";
import HtmlContent from "../../html-content";
import Carousel from "../../carousel";
import ButtonPrimary from "../../button-primary";
import { URLJoin } from "../../../utils/string";
import "./style.scss";

const HiddenCollaborationPageContent = ({
  button,
  text_1,
  text_2,
  image_1,
  image_2,
}) => {
  return (
    <div className="c-hidden-collaboration-page-content">
      <div className="row mt-4">
        <div className="col-md-6 mt-md-3">
          <Carousel
            animationHandler="fade"
            transitionTime={2500}
            hideControls
            images={image_1}
          />
        </div>
        <div className="col-md-6 mt-3">
          <HtmlContent content={text_1} />
        </div>
      </div>
      <div className="row mt-2 mt-md-5">
        <div className="col-md-6 mt-3 ">
          <HtmlContent content={text_2} />
          <div className="mt-4">
            {button && (
              <ButtonPrimary
                title={button.title}
                to={URLJoin("/", button.url)}
              />
            )}
          </div>
        </div>
        <div className="col-md-6 mt-md-3 order-first order-md-last">
          <Carousel hideControls animationHandler="fade" images={image_2} />
        </div>
      </div>
    </div>
  );
};
export default HiddenCollaborationPageContent;
